exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diferencia-js": () => import("./../../../src/pages/diferencia.js" /* webpackChunkName: "component---src-pages-diferencia-js" */),
  "component---src-pages-estudios-venta-js": () => import("./../../../src/pages/estudios-venta.js" /* webpackChunkName: "component---src-pages-estudios-venta-js" */),
  "component---src-pages-fundadora-js": () => import("./../../../src/pages/fundadora.js" /* webpackChunkName: "component---src-pages-fundadora-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tarifas-js": () => import("./../../../src/pages/tarifas.js" /* webpackChunkName: "component---src-pages-tarifas-js" */)
}

